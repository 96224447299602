import Layout from '@components/layout/Layout';
import Container from '@components/ui/container/Container';
import Footer from '@components/ui/footer/Footer';
import Header from '@components/ui/header/Header';
import HighlightsList from '@components/ui/highlights-list/HighlightsList';
import TitleBar from '@components/ui/title-bar/TitleBar';
import LanguageProvider from '@lib/language.provider';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const ITPage = ({ pageContext, location }) => {
	i18n.activate(pageContext.language);

	const data = [
		t`Kompleksowe wsparcie wdrożeń IT w przedsiębiorstwach (systemy ERP, billingowe i inne).`,
		t`Przygotowywanie umów SLA (service level agreement) oraz doradztwo przy ich negocjowaniu.`,
		t`Przygotowywanie umów dotyczących praw własności intelektualnej: umowy licencyjne oraz umowy przeniesienia praw własności intelektualnej.`
	];

	const highlights = [
		{
			label: t`Świadczymy usługi doradztwa`,
			description: t`prawnego w zakresie szeroko rozumianej ochrony praw własności intelektualnej`
		},
		{
			label: t`Ponad 15`,
			description: t`wdrożeń systemów informatycznych`
		},
		{
			label: t`Ponad 70`,
			description: t`umów SLA`
		}
	];

	return (
		<LanguageProvider>
			<Layout>
				<main>
					<title>
						<Trans>IT i media</Trans>
					</title>
					<Header location={location.pathname} />
					<div className="flex flex-col min-h-screen">
						<TitleBar>
							<div className="relative z-10 pt-10 w-full text-white md:max-w-md lg:max-w-2xl md:pr-10 lg:pr-20">
								<div className="text-sm uppercase">
									<Trans>Specjalizacje</Trans>
								</div>
								<h1 className="font-serif text-4xl font-bold">
									<Trans>IT i media</Trans>
								</h1>
							</div>
							<div className="absolute top-0 left-0 w-full h-full">
								<StaticImage
									className="block object-cover w-full h-full"
									src="../../assets/images/fuzje-i-przejecia-photo-01.jpg"
									alt={t`Kontakt`}
									placeholder={'none'}
								/>
							</div>
						</TitleBar>
						<div>
							<Container>
								<div className="grid gap-8 py-8 lg:gap-16 md:grid-cols-2 md:py-12">
									<div className="text-gray-dark">
										<h2 className="mb-3 font-bold">Zakres usług</h2>
										<HighlightsList items={data} />
									</div>
									<div>
										<div className="grid grid-cols-2 gap-2 lg:gap-3">
											{highlights.map((item) => (
												<div className="p-4 border lg:p-8 border-gray-medium first:col-span-2">
													<div className="w-full">
														<div className="mb-3 text-2xl font-bold">
															{item.label}
														</div>
														<div className="text-sm text-gray-dark">
															{item.description}
														</div>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							</Container>
						</div>
						<Footer />
					</div>
				</main>
			</Layout>
		</LanguageProvider>
	);
};

export default ITPage;
